.App {
  font-family: sans-serif;
  text-align: center;
}
h1 {
  color: #e94b32;
}
ul.nav {
  width: 60%;
  margin: 0 auto 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #39A2DB;
  border-radius: 2rem;
  padding-left: 0px;
}
ul.nav li {
  width: 50%;
  padding: 1rem;
  list-style: none;
  text-align: center;
  cursor: pointer;
  transition: all 0.7s;
  border-bottom-left-radius: 2rem;
  border-top-left-radius: 2rem;
  font-weight: bold;
  color: #39A2DB;
}
ul.nav li:nth-child(2) {
  border-radius: 0 2rem 2rem 0;
}
ul.nav li:hover {
  background: rgba(50, 224, 196, 0.15);
}
ul.nav li.active {
  background: #39A2DB;
  color: white;
  font-weight: bold;
}

.content.post, .content.get {
  display: none;
}
.content.post.active, .content.get.active {
  display: block;
}

h2 {
  color: #86d0ef;
}
.File {
  display: inline-block;
  text-align: left;
}

button:hover {
  background-color: lightslategray;
}
.error , .warn {
  color: #e94b32;
}
label {
  margin-right: 15px;
}
.folderId {
  padding: 1em;

}
button {
  left: 0;
  display: inline-block;
  width: auto;
  height: auto;
  cursor: pointer;
  margin-top: 4em;
  margin-right: auto;
  margin-left: auto;
  padding: 16px 25px 14px;
  border-style: none;
  border-radius: 20px;
  background-color: #2e4052;
  font-family: Hurmegeometricsans1, sans-serif;
  color: #fff;
  font-size: 16px;
  line-height: 16px;
  font-style: normal;
  text-align: center;
  letter-spacing: 1px;
  text-decoration: none;
}

table,
tbody,
td,
tfoot,
th,
thead,
tr {
  border: 1px solid #dee2e6;
  padding: 12px;
}
tbody {
  border: 1px solid #dee2e6;
}
table > tbody > tr:nth-of-type(odd) > * {
  background-color: rgb(240, 240, 240);
}

.download {
  margin-right: 1em;
}

.loader {
  position: fixed;
  left: 0px;
  top: 50%;
  width: 100%;
  height: 100%;
  z-index: 9999;
}
